import React, {useEffect, useMemo, useRef, useState} from "react";
import {Modal} from "react-bootstrap";

import "./MarketModal.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import iconBalance from "assets/img/board/ic_investment_accounting_box.svg";
import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardUtil from "utils/CardUtil";
import {useTranslation} from "react-i18next";
import DemoSessionService from "services/common/DemoSessionService";
import DemoPlaceholder from "../../ConceptionModal/components/DemoPlaceholder";
import {GAME_CARD_TYPE} from "config/CONSTANTS";
import BtnScrollToTop from "components/BtnScrollToTop";

export default function MarketModal({children, type, cards, onClose, forceRerender, handleRenderItem}) {
    const {t} = useTranslation('pages/common/board/market_modal');
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const isMobile = useSelector(state => state.session.isMobile);

    const modalBody = useRef();

    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isFinished = useSelector(state => state.game.isFinished);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const [filterName, setFilterName] = useState('');
    const [sortBy, setSortBy] = useState('price');
    const [sortOrder, setSortOrder] = useState('asc');

    const cardsToRender = useMemo(() => {
        let cardsToDisplay = isDemonstration ? cards.slice(0, Math.round(cards.length / 2)) : [...cards];
        let cardAreas = [];

        if (!!filterName) {
            const filter = filterName.toLowerCase();
            cardsToDisplay = cardsToDisplay.filter(s => {
                return s.title.toLowerCase().includes(filter) || s.area.toLowerCase().includes(filter)
            });
        }

        let sortFn = false;

        if (sortBy === 'name') {
            sortFn = (a, b) => a.title.localeCompare(b.title);
        } else if (sortBy === 'power') {
            sortFn = (a, b) => CardUtil.getPower(a) - CardUtil.getPower(b);
        }

        if (sortFn)
            cardsToDisplay = cardsToDisplay.sort(sortFn);

        if (sortOrder === 'desc')
            cardsToDisplay = cardsToDisplay.reverse();

        cardsToDisplay.forEach(c => {
            if (!cardAreas[c.area])
                cardAreas[c.area] = [];

            cardAreas[c.area].push(handleRenderItem(c));
        });

        cardAreas = cardAreas.sort((a, b) => a.localeCompare(b))

        return cardAreas;
    }, [forceRerender, filterName, sortBy, sortOrder, cards]);

    const handleClearFilter = () => {
        setFilterName('');
    };

    useEffect(() => {
        if (isDemonstration)
            DemoSessionService.setVisitedMarket();
    }, []);


    return (
        <Modal show={true} size={'xl'} onHide={onClose}
               className={`market-modal ${type === GAME_CARD_TYPE.SKILL ? 'skills' : 'services'}-market`}
               scrollable={!isMobile}
               ref={modalBody}>

            <Modal.Header closeButton className={`bg-dark text-white`}>
                <div className="modal-header-content w-100 d-flex flex-column flex-md-row align-items-md-center">
                    <img alt="Mundi Game Experiences logo" src={logo} className="mr-4 d-none d-md-inline-block"/>

                    <div>
                        {children}
                    </div>

                    <div className="available-for-market flex-fill d-none d-md-flex justify-content-end">
                        <div className="px-3 py-2">
                            <p className="mb-1">{t('texts.available_for_market')}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <img alt="Balance icon" src={iconBalance} className="mr-2"/>
                                <h5 className="mb-0 text-warning">{i18nUtil.formatMoney(availableForMarket || 0)}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 d-flex justify-content-between flex-wrap d-md-none">
                        <div className="d-flex flex-wrap align-items-center">
                            <h5 className="mb-0 mr-2">{t('texts.available_for_market')}:</h5>
                            <h5 className="mb-0 text-warning font-weight-bold">
                                {i18nUtil.formatMoney(availableForMarket || 0)}
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div
                    className="filters w-100 p-2 text-white bg-primary rounded d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">

                    <div className="d-flex form-inline mb-2 mb-md-0 flex-md-row align-items-center">
                        <div className="form-group mr-md-2 mb-0">
                            <label className="small mr-1">{t('inputs.find.label')}</label>
                            <input type="text" className="form-control-sm d-block" value={filterName}
                                   onChange={event => setFilterName(event.target.value)}/>
                        </div>

                        <button type="button" className="btn btn-link p-0 text-light small mt-1 mt-md-0"
                                onClick={handleClearFilter}>
                            {t('buttons.clear')}
                        </button>
                    </div>

                    <div className="form-inline">
                        <div className="form-group mr-md-4 mr-md-2">
                            <label className="small mr-1">{t('inputs.sort_by.label')}</label>

                            <select className="form-control-sm d-block" value={sortBy}
                                    onChange={event => setSortBy(event.target.value)}>
                                <option value="price">{t('inputs.sort_by.options.price')}</option>
                                <option value="name">{t('inputs.sort_by.options.name')}</option>
                                <option value="power">{t('inputs.sort_by.options.power')}</option>
                            </select>
                        </div>

                        <div className="form-group mr-md-2">
                            <label className="small mr-1">{t('inputs.sort_order.label')}</label>

                            <select className="form-control-sm d-block" value={sortOrder}
                                    onChange={event => setSortOrder(event.target.value)}>
                                <option value="asc">{t('inputs.sort_order.options.asc')}</option>
                                <option value="desc">{t('inputs.sort_order.options.desc')}</option>
                            </select>
                        </div>
                    </div>
                </div>

                {(!canHireCards || isFinished) && (
                    <div className="d-flex justify-content-center">
                        <div className="py-2 px-5 text-center w-auto rounded d-flex align-items-center">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="fa-2x mr-3"/>
                            <h5 className="mb-0 text-uppercase d-inline">
                                {!canHireCards && !isFinished && t('texts.market_closed')}
                                {isFinished && t('texts.game_finished')}
                            </h5>
                        </div>
                    </div>
                )}

                {Object.entries(cardsToRender).map(([area, _cards]) => {
                    return (<div key={area} className="mt-4">
                        <h4 className="ml-3 mb-3">{i18nUtil.area(area)}</h4>
                        <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                            {_cards}

                            {isDemonstration && (
                                <DemoPlaceholder clasz={"demo-card"}/>
                            )}
                        </div>

                        <hr/>
                    </div>)
                })}

                {Object.entries(cardsToRender).length === 0 && (
                    <div className="d-flex justify-content-center w-100">{t('texts.no_results')}</div>
                )}
            </Modal.Body>

            {isMobile && (<BtnScrollToTop parentSelector={'.market-modal'}/>)}
        </Modal>
    );
}