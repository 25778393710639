import React from "react";
import {Header} from "../componentsDesktop/Header";
import StartupProfile from "../componentsDesktop/StartupProfile/StartupProfileSummaryCard";
import Skills from "../componentsDesktop/SkillsSummaryCard/SkillsSummaryCard";
import Services from "../componentsDesktop/ServicesSummaryCard/ServicesSummaryCard";
import {GAME_STEPS} from "../../../../config/CONSTANTS";
import {Challenges} from "../components/Challenges/Challenges";
import GameEvents from "../componentsDesktop/GameEvents";
import JourneyMap from "../componentsDesktop/JourneyMap/JourneyMapSummaryCard";
import Accounting from "../componentsDesktop/Accounting/AccountSummaryCard";
import {useSelector} from "react-redux";
import Footer from "../components/Footer";


export default function Desktop() {
    const currentStep = useSelector(state => state.game.currentStep);

    return (<div id="board" className="desktop min-vh-100 overflow-hidden d-flex flex-column position-relative">
        <Header/>

        <div
            className="flex-fill d-flex justify-content-center  w-100 animate__animated animate__fast animate__fadeIn">
            <div className={'board d-flex justify-content-around w-100 container-custom'}>
                <div className="d-flex flex-column flex-fill">
                    <div className="d-flex">
                        <div className={'left-content pl-2'}>
                            <StartupProfile/>

                            <div className="d-flex">
                                <Skills/>
                                <Services/>
                            </div>
                        </div>

                        <div className={'center-content flex-fill mx-2 h-100'}
                             style={{zIndex: currentStep === GAME_STEPS.CONCEPTION ? 5 : 1}}>
                            <div className="h-100 ">
                                <Challenges/>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 pr-4 mx-3 mt-3">
                        <GameEvents/>
                    </div>
                </div>

                <div className={'right-content pr-2'}>
                    <JourneyMap/>
                    <Accounting/>
                    <Footer/>
                </div>
            </div>
        </div>
    </div>);
}