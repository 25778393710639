import "./Sidebar.scss";
import {Portal} from "react-portal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";

const TRANSITION_DURATION = 1000; // in ms

export default function Sidebar({title, styleClass, from = 'left', children, show, onClose}) {
    const [timer, setTimer] = React.useState(null);
    const [render, setRender] = React.useState(false);

    const clasz = show ? 'visible' : 'hidden';
    const style = styleClass ?? 'bg-primary';

    useEffect(() => {
        clearTimeout(timer);

        if (show)
            setRender(true);
        else
            setTimer(setTimeout(() => setRender(false), TRANSITION_DURATION))

        return () => clearTimeout(timer);
    }, [show]);


    return <Portal node={document.getElementById('sidebar-' + from)}>
        <div className={`sidebar ${clasz}`}>
            {render && (<>
                <div className="sidebar-scroll-container h-100 w-100 overflow-auto">
                    <div
                        className={`sidebar-header shadow d-flex justify-content-between px-4 py-2 text-white align-items-center ${style}`}>
                        <h5 className="mb-0">{title ?? ''}</h5>
                        <button className="btn btn-primary bg-transparent border-0 p-0" onClick={onClose}>
                            <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                        </button>
                    </div>


                    <div className="sidebar-content mt-3">
                        {children}
                    </div>
                </div>
            </>)}
        </div>
    </Portal>
}