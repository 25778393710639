import React from "react";

import "./UserList.scss";
import "components/cards/layouts/SmgxCardLayout.scss";

import SimpleBar from "simplebar-react";
import {useTranslation} from "react-i18next";
import PlayersTable from "../components/PlayersTable";
import GameMasterInfo from "../components/GameMasterInfo";

export default function UserList() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.user_list'});


    return (
        <div className="smgx-card w-100 user-list">
            <div
                className="box d-inline-block border-warning w-100 h-100">
                <div className="box-head bg-warning text-uppercase">{t('texts.box_title')}</div>

                <div className="box-content mr-0">
                    <GameMasterInfo iconWidth={60} textClass={'h3'}/>

                    <SimpleBar style={{maxHeight: 470, height: 470}} className="simplebar pr-2">
                        <PlayersTable/>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
}