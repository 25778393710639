import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {setIsMobile} from "redux/slices/sessionSlice";
import {useTranslation} from "react-i18next";

export default function ButtonToggleMobile({className}) {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.header'});

    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.session.isMobile);

    const handleToggleMobile = () => {
        dispatch(setIsMobile(!isMobile));
    }

    return (<button className={className} onClick={handleToggleMobile}>
        {!isMobile && (<>
            <FontAwesomeIcon icon={['fas', 'mobile']} className="mr-2"/>
            {t('buttons.mobile_version')}
        </>)}

        {isMobile && (<>
            <FontAwesomeIcon icon={['fas', 'desktop']} className="mr-2"/>
            {t('buttons.desktop_version')}
        </>)}
    </button>)
}