import React, {useEffect, useMemo} from "react";
import {Header} from "../componentsMobile/Header";
import {GAME_STEPS} from "config/CONSTANTS";
import {useSelector} from "react-redux";
import store from "redux/store";
import {setSelectedMobileTab} from "redux/slices/sessionSlice";
import StartupProfile from "../componentsMobile/StartupProfile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HiredCards from "../componentsMobile/HiredCards/HiredCards";
import {Challenges} from "../components/Challenges/Challenges";
import JourneyMapSummaryCard from "../componentsMobile/JourneyMapSummaryCard";
import Accounting from "../componentsMobile/Accounting/Accounting";
import {MOBILE_GAME_TABS, MOBILE_TAB_ICON} from "config/MOBILE";
import TabsFooter from "../componentsMobile/TabsFooter";
import NotificationWatcher from "../componentsMobile/NotificationWatcher";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../hooks/useTranslationForGame";


export default function Mobile() {
    const {t} = useTranslation('pages/common/board/mobile_tabs');
    const tfg = useTranslationForGame(t);

    const currentStep = useSelector(state => state.game.currentStep);
    const selectedMobileTab = useSelector(state => state.session.selectedMobileTab);
    const game = useSelector(state => state.game.gameInfo.game);

    const icon = useMemo(() => {
        if (typeof MOBILE_TAB_ICON[selectedMobileTab] === 'object')
            return MOBILE_TAB_ICON[selectedMobileTab]?.[game] ?? 'question-circle'

        return MOBILE_TAB_ICON[selectedMobileTab];
    }, [selectedMobileTab, game]);


    useEffect(() => {
        store.dispatch(setSelectedMobileTab(MOBILE_GAME_TABS.CHALLENGES));
    }, []);

    return (<div id="board" className="mobile min-vh-100  position-relative">
        <Header/>
        <NotificationWatcher/>

        <div className="board container w-100 container py-3  animate__animated animate__fast animate__fadeIn"
             style={{zIndex: currentStep === GAME_STEPS.CONCEPTION ? 5 : 2}}>

            <h3 className="mb-4 text-white text-center">
                <FontAwesomeIcon icon={['fas', icon]} className="mr-2"/>
                {tfg(selectedMobileTab)}
            </h3>

            {selectedMobileTab === MOBILE_GAME_TABS.CHALLENGES && (<Challenges/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.STARTUP_PROFILE && (<StartupProfile/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.HIRED_CARDS && (<HiredCards/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.JOURNEY_MAP && (<JourneyMapSummaryCard/>)}
            {selectedMobileTab === MOBILE_GAME_TABS.ACCOUNTING && (<Accounting/>)}
        </div>

        <TabsFooter/>
    </div>);
}