import React, {useEffect, useMemo, useState} from "react";
import SummaryCardLayout from "components/cards/layouts/SummaryCardLayout";
import SimpleBar from 'simplebar-react';
import "./ServicesSummaryCard.scss";
import ServiceItem from "../../components/ServiceItem";
import ServiceShow from "./components/ServiceShow";
import {useSelector} from "react-redux";
import {GAME_PHASES} from "config/CONSTANTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import ServiceMarket from "../../components/Markets/ServiceMarket";

export default function ServicesSummaryCard() {
    const {t} = useTranslation('pages/common/board/hired_cards', {keyPrefix:'components.services'});

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentPhase = useSelector(state => state.game.currentPhase);
    const hiredServices = useSelector(state => state.teams[showBoardForTeam].services);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const hasVisitedMarket = useSelector(state => state.demo.state.hasVisitedMarket);
    const pulseMarket = useSelector(state => state.demo.state.pulseMarket);

    const [hiredServicesToShow, setHiredServicesToShow] = useState([]);
    const [serviceToShow, setServiceToShow] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const [showServiceModal, setShowServiceModal] = useState(false);
    const handleShowServiceModal = () => setShowServiceModal(true);
    const handleCloseServiceModal = () => setShowServiceModal(false);

    const handleShowAllServices = () => {
        if (!serviceToShow) return;
        setServiceToShow(false);
    };


    useEffect(() => {
        setShowContent(currentPhase > GAME_PHASES.PHASE_1);
    }, [currentPhase]);

    useEffect(() => {
        const servicesToShow = Object.values(hiredServices).reduce((acc, hiredService) => {
            return [...acc, ...hiredService];
        }, []);

        setServiceToShow(false);
        setHiredServicesToShow(servicesToShow);
    }, [showBoardForTeam, hiredServices]);

    const cardClass = useMemo(() => {
        return (serviceToShow !== false ? 'showing-skill ' : '')
            + (showContent ? 'border-warning ' : 'not-unlocked ')
            + (showContent && isDemonstration && pulseMarket && !hasVisitedMarket ? 'pulsating ' : '');
    }, [showContent, serviceToShow, pulseMarket, hasVisitedMarket, isDemonstration]);

    return (
        <>
            <SummaryCardLayout>
                <div
                    className={"box d-inline-block services-summary-card " + cardClass} onClick={handleShowAllServices}>
                    <div className={"box-head text-uppercase " + (showContent ? 'bg-warning' : 'transparent')}>
                        {showContent && (<>{t('texts.title')} <span
                            className="ml-3">{hiredServicesToShow.length}</span></>)}
                        {!showContent && (<>???</>)}
                    </div>

                    <div className={"box-content " + (showContent ? '' : 'd-none')}>
                        {hiredServicesToShow.length > 0 && (
                            <SimpleBar style={{maxHeight: 290}}>
                                {serviceToShow === false && (
                                    <div className="animate__animated animate__fadeInLeft animate__faster">
                                        {hiredServicesToShow.map((hiredService) =>
                                            <ServiceItem key={hiredService.id} hiredService={hiredService}
                                                         onClick={setServiceToShow}/>)}
                                    </div>
                                )}

                                {serviceToShow !== false && (
                                    <ServiceShow service={serviceToShow} onClose={handleShowAllServices}/>)}
                            </SimpleBar>
                        )}

                        {hiredServicesToShow.length === 0 && (
                            <div
                                className="w-100 h-100 text-center d-flex justify-content-center align-items-center pb-5 text-muted">
                                <h4 className="text-uppercase">{t('texts.no_hired')}</h4>
                            </div>
                        )}
                    </div>

                    <div
                        className={"box-content justify-content-center align-items-center " + (showContent ? 'd-none' : 'd-flex')}>
                        <FontAwesomeIcon icon={['fas', 'lock']} className="fa-3x mb-5 text-warning"/>
                    </div>

                    <div className={"box-footer " + (showContent ? 'bg-warning' : 'transparent')}>
                        <button id="btn-open-service-market" type="button" hidden={serviceToShow !== false}
                                className="w-100 h-100 bg-transparent border-0 text-uppercase"
                                onClick={handleShowServiceModal}>
                            {t("buttons.open_market")}
                        </button>

                        {serviceToShow !== false &&
                            (
                                <button type="button" className="w-100 h-100 bg-transparent border-0 text-uppercase"
                                        onClick={handleShowAllServices}>
                                    {t("buttons.show_all")}
                                </button>
                            )}
                    </div>
                </div>
            </SummaryCardLayout>

            {showServiceModal && (<ServiceMarket onClose={handleCloseServiceModal}/>)}
        </>
    );


}