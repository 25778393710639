import React from "react";
import {useSelector} from "react-redux";

import "./Messages.scss";
import "components/cards/layouts/SmgxCardLayout.scss";
import Message from "../components/Message";
import {useTranslation} from "react-i18next";

export default function Messages() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.messages'});
    const messages = useSelector(state => state.messages.messages);

    return (
        <div className="w-100 messages">
            <h5>{t('texts.box_header')}</h5>
            {messages.map((value, index) => (<Message key={index} message={value} bg={'dark'}/>))}
        </div>
    );
}