import React from 'react';
import TeamCard from "../components/TeamCard";

export default function TeamCards() {
    return (<div>
        <div className="d-flex mb-3">
            <TeamCard number={1}/>
            <TeamCard number={2}/>
        </div>

        <div className="d-flex">
            <TeamCard number={3}/>
            <TeamCard number={4}/>
        </div>
    </div>)
}