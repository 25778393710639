import React, {useEffect, useMemo, useState} from 'react';
import {GAME_STEPS, GAME_STEPS_WITH_PITCH} from "config/CONSTANTS";
import SessionPersistenceUtil, {PLAYER_KEY} from "utils/SessionPersistenceUtil";
import SwalModalUtil from "utils/SwalModalUtil";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PlayerFormModal from "../../PlayerFormModal/PlayerFormModal";
import NpsFormModal from "../../NpsFormModal/NpsFormModal";
import store from "../../../../redux/store";
import CardUtil from "../../../../utils/CardUtil";
import i18nUtil from "../../../../utils/i18nUtil";
import AudioService from "../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../config/AUDIO_FILES";
import ACCOUNTING_ESG_CONFIG from "../../../../config/ACCOUNTING_ESG_CONFIG";
import ACCOUNTING_SMGX_CONFIG from "../../../../config/ACCOUNTING_SMGX_CONFIG";
import GameUtil from "../../../../utils/GameUtil";
import useTranslationForGame from "../../../../hooks/useTranslationForGame";

const SHOW_CHECK_IN_MODAL_ON_PITCH_STEPS = [
    GAME_STEPS.PITCH_1, GAME_STEPS.PITCH_2, GAME_STEPS.PITCH_3
];

let lastStep = null;
let didRevealPitch3Bonus = false;

export default function PopUpNotificationsWatcher() {
    const {t} = useTranslation('pages/common/board/notifications');
    const tfg = useTranslationForGame(t);
    
    const currentStep = useSelector(state => state.game.currentStep);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const currentUser = useSelector(state => state.session.user);
    const allowCertificateRequest = useSelector(state => state.game.allowCertificateRequest);

    const [showPlayerCertificatesRequestModal, setShowPlayerCertificatesRequestModal] = useState(false);
    const [showNpsModal, setShowNpsModal] = useState(false);
    
    
    // ------------

    const isTournament = useSelector(state => state.game.isTournament);
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);
    const showStepChangeTransition = useSelector(state => state.tournament.state.showStepChangeTransition);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const ranking = useSelector(state => state.game.ranking);


    useEffect(() => {
        if (isGameMaster || !allowCertificateRequest)
            return;

        if ((!currentUser.certificatesRequested || currentUser.certificatesRequested === 0) &&
            ((currentStep === GAME_STEPS.ONBOARDING && currentUser.team) || SHOW_CHECK_IN_MODAL_ON_PITCH_STEPS.includes(currentStep))
            && !SessionPersistenceUtil.get(PLAYER_KEY)?.shown_check_in) {

            SwalModalUtil.confirmModal(
                t('check_in_now.title'),
                t('check_in_now.message'),
                t('check_in_now.confirm_button'),
                t('check_in_now.cancel_button')
            ).then(result => {
                if (result)
                    setShowPlayerCertificatesRequestModal(true);

                SessionPersistenceUtil.update(PLAYER_KEY, {shown_check_in: true});
            })
        }

        if (currentStep === GAME_STEPS.END_GAME && !SessionPersistenceUtil.get(PLAYER_KEY)?.shown_nps_form) {
            SwalModalUtil.confirmModal(
                t('submit_nps_now.title'),
                t('submit_nps_now.message'),
                t('submit_nps_now.confirm_button'),
                t('submit_nps_now.cancel_button')
            ).then(result => {
                if (result)
                    setShowNpsModal(true);

                SessionPersistenceUtil.update(PLAYER_KEY, {shown_nps_form: true});
            })
        }
    }, [currentStep, currentUser, isGameMaster]);

    useEffect(() => {
        if (showStepChangeTransition)
            return;

        if (lastStep && GAME_STEPS_WITH_PITCH.includes(lastStep) && lastStep !== GAME_STEPS.PITCH_3) {
            const accounting = store.getState().teams[showBoardForTeam]?.accounting;
            const phase = CardUtil.getGamePhaseForStep(lastStep);

            if (accounting && accounting.phases && accounting.phases[phase] && accounting.phases[phase].steps &&
                accounting.phases[phase].steps[lastStep] && accounting.phases[phase].steps[lastStep].capital) {

                const investment = i18nUtil.formatMoney(accounting.phases[phase].steps[lastStep].capital);
                let title, message, buttonText;

                if (isTournament) {
                    title = tfg('tournament_investment_received.' + lastStep.toString() + '.title', {investment});
                    message = tfg('tournament_investment_received.' + lastStep.toString() + '.message', {investment});
                    buttonText = t('tournament_investment_received.' + lastStep.toString() + '.confirm_button');
                } else {
                    title = i18nUtil.stepName(lastStep).toUpperCase();
                    message = t('investment_received.message', {investment});
                    buttonText = t('investment_received.confirm_button');
                }

                SwalModalUtil.infoModal(
                    title,
                    message,
                    buttonText
                ).then(() => AudioService.play(SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED));
            }
        }

        if (lastStep && ranking && ranking[showBoardForTeam]?.show === true && !didRevealPitch3Bonus) {
            const accountingConfig = isEsg
                ? ACCOUNTING_ESG_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_2]
                : ACCOUNTING_SMGX_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_3];

            const investmentReceived = accountingConfig[ranking[showBoardForTeam].ranking];

            if (investmentReceived > 0) {
                const investment = i18nUtil.formatMoney(investmentReceived);
                let title, message, buttonText;

                if (isTournament) {
                    title = tfg('tournament_investment_received.' + lastStep.toString() + '.title', {investment});
                    message = tfg('tournament_investment_received.' + lastStep.toString() + '.message', {investment});
                    buttonText = t('tournament_investment_received.' + lastStep.toString() + '.confirm_button');
                } else {
                    title = i18nUtil.stepName(lastStep).toUpperCase();
                    message = t('investment_received.message', {investment});
                    buttonText = t('investment_received.confirm_button');
                }

                SwalModalUtil
                    .infoModal(title, message, buttonText)
                    .then(() => AudioService.play(SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED));

                didRevealPitch3Bonus = true;
            }
        } else if (ranking && ranking[showBoardForTeam]?.show === true) {
            didRevealPitch3Bonus = true;
        }

        lastStep = currentStep;
    }, [currentStep, ranking, showBoardForTeam, showStepChangeTransition, isEsg]);

    
    if (showPlayerCertificatesRequestModal)
        return (<PlayerFormModal player={!isGameMaster ? currentUser : null}
                                 onClose={() => setShowPlayerCertificatesRequestModal(false)}/>);

    if (showNpsModal)
        return (<NpsFormModal player={!isGameMaster ? currentUser : null} onClose={() => setShowNpsModal(false)}/>)


    return null;
}