import React from "react";
import Header from "../componentsDesktop/Header";
import FadeIn from "../../../../assets/plugins/react-fade-in/FadeIn";
import Footer from "../components/Footer";
import Messages from "../componentsDesktop/Messages";
import UserList from "../componentsDesktop/UserList";
import TeamCards from "../componentsDesktop/TeamCards";

export default function Desktop() {
    return (<div id="lobby" className="desktop d-flex flex-column m-auto h-100">
        <Header/>

        <FadeIn className="flex-fill d-flex justify-content-center">
            <div className="d-flex mt-4 justify-content-center container-custom">
                <UserList/>
                <TeamCards/>
                <Messages/>
            </div>
        </FadeIn>

        <Footer/>
    </div>);
}