import {useSelector} from "react-redux";
import {MOBILE_GAME_TABS} from "config/MOBILE";
import SessionReduxService from "services/redux/SessionReduxService";
import {useEffect} from "react";
import {GAME_STEPS} from "config/CONSTANTS";


export default function NotificationWatcher() {
    const currentStep = useSelector(state => state.game.currentStep);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    // hired cards
    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isFinished = useSelector(state => state.game.isFinished);

    // journey map
    const pitchBonus = useSelector(state => state.teams[showBoardForTeam].pitchBonus[currentStep]);

    // startup profile
    const startup = useSelector(state => state.teams[showBoardForTeam].startup);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);
    const valuePropositions = useSelector(state => state.teams[showBoardForTeam].valuePropositions);


    useEffect(() => {
        SessionReduxService.updateNotification({
            [MOBILE_GAME_TABS.HIRED_CARDS]: canHireCards && !isFinished && availableForMarket > 6000,
        });
    }, [availableForMarket, canHireCards, isFinished]);

    useEffect(() => {
        SessionReduxService.updateNotification({
            [MOBILE_GAME_TABS.JOURNEY_MAP]: !!pitchBonus
        });
    }, [currentStep, pitchBonus]);


    useEffect(() => {
        const show = currentStep === GAME_STEPS.CONCEPTION
            && Object.keys(founders).length === 2
            && Object.keys(valuePropositions).length === 2
            && !!startup;

        SessionReduxService.updateNotification({
            [MOBILE_GAME_TABS.STARTUP_PROFILE]: show,
            [MOBILE_GAME_TABS.CHALLENGES]: !show
        });
    }, [currentStep, founders, valuePropositions, startup]);

    useEffect(() => {
        if (currentStep > GAME_STEPS.CONCEPTION && currentStep < GAME_STEPS.END_GAME) {
            SessionReduxService.updateNotification({
                [MOBILE_GAME_TABS.CHALLENGES]: true
            });
        } else if (currentStep >= GAME_STEPS.END_GAME) {
            SessionReduxService.updateNotification({
                [MOBILE_GAME_TABS.CHALLENGES]: false
            });
        }
    }, [currentStep]);


    return null;
}