import awsExports from "../aws-exports";

export const APP_VERSION = 'v4.0.0';
export const i18N_VERSION = APP_VERSION;
export const LATEST_POLICIES_VERSION = 'v1.1.0';

export const IS_PRODUCTION_ENV = awsExports.aws_dynamodb_table_schemas[0].tableName.endsWith('prod');
export const IS_LOCAL_ENV = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1');

export const SMGX_GAME_ADMIN_URL = IS_PRODUCTION_ENV
    ? 'https://panel.startupmundi.com'
    : 'https://dev.panel.startupmundi.com';


export const GAMES = {
    SMGX: 'smgx',
    ESG: 'esg'
};

export const ACCELERATION_BONUSES = {
    IMPLEMENTATION: 'implementation',
    MONITORING: 'monitoring',
    REPORTING: 'reporting',
    GENDER_DIVERSITY: 'gender_diversity',
    RACE_DIVERSITY: 'race_diversity'
};

export const CHALLENGE_TYPE = {
    OBJECTIVE: 'objective',
    QUESTION: 'question',
    PITCH_CARD: 'pitch',
    UNPREDICTABILITY: 'unpredictability',
    RANKING: 'ranking',
    TEAM_RANKING: 'team_ranking',

    REPORTING_BONUS: 'reporting',
    DEMO_ENDING: 'demo_ending',

    TOURNAMENT_PITCH: 't_pitch',
    TOURNAMENT_ENDING: 't_end',
    TOURNAMENT_BETA_NPS: 't_nps',
    TOURNAMENT_RANKING: 't_ranking'
}

export const GAME_EVENT_TYPES = {
    INFO: 'info',
    UNPREDICTABILITY: 'unpredictability',
    QUESTION: 'question',
    PITCH_BONUS: 'pitch_bonus',
    PITCH_INVESTMENT: 'pitch_investment',
    PHASE_SEPARATOR: 'phase_separator',
}

export const OVERLAY_THEME = {
    DEFAULT: 'overlay-default',
    DARK: 'overlay-dark',
}

export const SERVICE_CARD_TYPE = {
    BASIC: 'basic',
    PACK: 'pack',
    POWER_PACK: 'power_pack'
}

export const UNPREDICTABILITY_TYPE = {
    NEUTRAL: 'neutral',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
};

export const CARD_AREA = {
    OPERATIONAL: 'operational',
    BUSINESS: 'business',
    CUSTOMER_SUCCESS: 'customer_success',
    PRODUCT: 'product',
};

export const CARD_AREA_ESG = {
    INNOVATION: 'INNOVATION',
    MANAGEMENT: 'MANAGEMENT',
    SUSTAINABILITY: 'SUSTAINABILITY',
    ENGAGEMENT: 'engagement',
};


export const REQUIREMENT_AREA = {
    SKILL: 'skill',
    SERVICE: 'service',
};

export const PLAYER_ACCESS_CONTROL = {
    ALL: 'A',
    IN_SESSION: 'IS',
    NONE: 'N'
};

export const GAME_STEPS = {
    AWAITING: 0,

    // phase 1
    ONBOARDING: 1,
    CONCEPTION: 2,
    MVP: 3,
    PITCH_1: 4,

    // phase 2
    BETA: 5,
    GTM: 6,
    GROWTH_MODEL: 7,
    PITCH_2: 8,

    // phase 3
    SALES_MACHINE: 9,
    CUSTOMER_SUCCESS: 10,
    PRODUCT_SCALABILITY: 11,
    PITCH_3: 12,

    // end game
    END_GAME: 13,
};

export const GAME_STEPS_WITH_QUESTIONS = [
    GAME_STEPS.MVP,
    GAME_STEPS.BETA, GAME_STEPS.GTM, GAME_STEPS.GROWTH_MODEL,
    GAME_STEPS.SALES_MACHINE, GAME_STEPS.CUSTOMER_SUCCESS, GAME_STEPS.PRODUCT_SCALABILITY
];

export const GAME_STEPS_WITH_PITCH = [
    GAME_STEPS.PITCH_1, GAME_STEPS.PITCH_2, GAME_STEPS.PITCH_3
];

export const GAME_PHASES = {
    PHASE_1: 1,
    PHASE_2: 2,
    PHASE_3: 3,
    END_GAME: 4
};

export const UNPREDICTABILITY_IMPACT = {
    NEUTRAL: 'neutral',
    POSITIVE: 'positive',
    NEGATIVE: 'negative'
}

export const GAME_CARD_TYPE = {
    SKILL: 'skill',
    SERVICE: 'service'
};

export const SESSION_STATUS = {
    OK: 'ok',
    CLOSED: 'closed',
    PAUSED: 'paused',
    NOT_STARTED: 'not_started',
    LIMITED: 'limited',
    NOT_FOUND: '404'
}


if (window) {
    window.APP_VERSION = APP_VERSION;
}