import React, {useMemo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";

export default function NavigationButtons({current, total, onPrevious, onNext, pulsate}) {
    const disablePagination = useSelector(state => state.demo.disableChallengesPagination);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const navigationClass = useMemo(() => {
        if (pulsate && (!isDemonstration || (isDemonstration && !disablePagination)))
            return 'pulsating';

        if (isDemonstration && disablePagination)
            return 'disabled';

        return '';
    }, [pulsate, isDemonstration, disablePagination]);

    return (<div
        className={`navigation d-flex justify-content-center mb-3 text-light ${navigationClass}`}>

        <button type="button" onClick={onPrevious}
                disabled={isDemonstration && disablePagination}
                className={"btn btn-outline-light border-0 px-2 py-0 mr-1 "}>
            <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
        </button>

        <h4 className="mb-0 mx-4 font-weight-bold">
            {current + 1}
            <span className="small">/</span>
            {total}
        </h4>

        <button type="button" onClick={onNext}
                disabled={isDemonstration && disablePagination}
                className={"btn btn-outline-light border-0 px-2 py-0"}>
            <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
        </button>
    </div>)
}