import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import "./PlayersTable.scss";


export default function PlayersTable() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.user_list'});

    const player = useSelector(state => state.session.user);
    const players = useSelector(state => state.game.players);

    const [playersToDisplay, setPlayersToDisplay] = useState([]);

    useEffect(() => {
        setPlayersToDisplay([...players].sort((a, b) => {
            if (a?.nickname && b?.nickname) return a.nickname.localeCompare(b.nickname)
            return 0;
        }));
    }, [players])

    return (
        <table className="table table-striped table-borderless lobby-table-players">
            <thead>
            <tr>
                <th className="fit-content text-center">#</th>
                <th className="fit-content text-center">{t('tables.users.header.team')}</th>
                <th>{t('tables.users.header.player')}</th>
                <th className="fit-content"/>
            </tr>
            </thead>

            <tbody className="">
            {playersToDisplay.length ? (
                playersToDisplay.map((p, index) =>
                    <tr key={p.id}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">
                                                <span
                                                    className={`badge badge-team-${p.team}`}>{p.team || '?'}</span>
                        </td>
                        <td>
                                            <span className={p.id === player.id ? 'current-player' : ''}>
                                                {p.nickname}
                                            </span>

                            {p.localPlayers && p.localPlayers.length > 0 && (
                                <p className="small text-info m-0 local-players">
                                    {p.localPlayers.map(lp => lp.nickname).join(', ')}
                                </p>
                            )}
                        </td>
                        <td className="text-center player-status">
                                                <span
                                                    className={p.isConnected ? 'player-online' : 'player-offline'}/>
                        </td>
                    </tr>
                )
            ) : null}

            {!playersToDisplay.length ? (
                <tr>
                    <td colSpan={4} className="text-muted small text-center">
                        {t('tables.users.empty_message')}
                    </td>
                </tr>
            ) : null}
            </tbody>
        </table>
    )
}