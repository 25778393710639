import React from 'react';
import {Modal} from "react-bootstrap";
import SkillCard from "components/cards/SkillCard";
import ServiceCard from "components/cards/ServiceCard";

import "./DetailsModal.scss";

export default function DetailModal({type, card, onClose}) {
    return (<Modal id="mobile-hired-card-details-modal" show={true} onHide={onClose} className="fade"
                   scrollable={true}>

        <Modal.Header closeButton className="">
            <Modal.Title>
                Detalhes da Contratação
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-center align-items-start">
            {type === 1 &&
                <SkillCard skill={card.card} icon={card.icon} gender={card.gender} hirable={false}/>
            }

            {type === 2 &&
                <ServiceCard service={card.card}  hirable={false}/>
            }
        </Modal.Body>
    </Modal>);
}